import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";

function App() {
  const [count, setCount] = useState(2);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  return (
    <>
      <div className="greed-container">
        <h1>trAsh kingZz</h1>
        <div className="greed-wrapper">
            <a
              href="https://opensea.io/collection/trash-kingz"
              target="_blank"
              rel="noreferrer"
              className="greed-link-item"
            >
              <Opensea />
            </a>
            <a
              href="https://twitter.com/trashkingz_wtf"
              target="_blank"
              rel="noreferrer"
              className="greed-link-item greed-twitter"
            >
              <Twitter />
            </a>
        </div>
        <div className="greed-form">  
          <h2>1 FREE MINT for EVERYONE</h2>
          {!!contractDetails && (
            <p className="counter">
              {mintedCount} / 10000
            </p>
          )} 
          {!!userData ? (
            <>
              <div className="greed-form-counter">
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="minus"
                  disabled={true}
                >
                  -
                </button>
                <span> {count} </span>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="plus"
                             disabled={true}
                >
                  +
                </button>
                <button
                  onClick={handleCustomMint}
                  type="button"
                  className="claim-btn"
                             disabled={true}
                >
                  Mint
                </button>
              </div>
              <div className="greed-claimers">
                <button
                  onClick={() => mint(3)}
                  type="button"
                  className="claim-btn"
                  disabled={true}
                >
                  Mint 3
                </button>
                <button
                  onClick={() => mint(5)}
                  type="button"
                  disabled={true}
                  className="claim-btn"
                >
                  Mint 5
                </button>
                <button
                  onClick={() => mint(10)}
                  type="button"
                  className="claim-btn"
                  disabled={true}
                >
                  Mint 10
                </button>
              </div>
              {transactionHash && (
                <a
                  href={`https://etherscan.io/tx/${transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="claim-btn transaction-hash"
                >
                  See transaction
                </a>
              )}
            </>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={connectBrowserWallet}
                                disabled={true}
            >
              SOLD OUT
            </button>
          )}
          <p>for second thE prize sick cheap 0.0055</p>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
